import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Presentational Components`}</h2>
    <p>{`We are focusing primarily on presentational components that help standardize common design patterns. Primer React components don't handle fetching and submitting data to/from APIs. If you would like to handle data in a Primer Component, feel free to create a wrapper around the Primer Component to do so.`}</p>
    <h2>{`Pattern Components vs Helper Components`}</h2>
    <p>{`Our components can roughly be categorized into two different component types:`}</p>
    <h3>{`Pattern Components`}</h3>
    <p>{`Pattern components help us repeat commonly used UI patterns and interactions in order to maintain our brand and provide a great user experience. Some examples of pattern components are `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, or `}<inlineCode parentName="p">{`Label`}</inlineCode>{`.`}</p>
    <h3>{`Helper Components`}</h3>
    <p>{`Helper components are components that help the user achieve common CSS patterns while maintaining some control over values used. An example of a helper component is `}<inlineCode parentName="p">{`Box`}</inlineCode>{`.`}</p>
    <h2>{`A note on flexibility`}</h2>
    <p><strong parentName="p">{`Assume that people will break the rules, provide safe ways for them to do so.`}</strong></p>
    <p>{`While we aim to standardize design in Primer React, we also provide additional styling flexibility through the `}<a parentName="p" {...{
        "href": "/guides/react/overriding-styles"
      }}><inlineCode parentName="a">{`sx`}</inlineCode>{` prop`}</a>{`. This enables small customizations to color and spacing, using values from the theme. Users also have the option to override the theme with a theme of their own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      